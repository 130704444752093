import { BookProfile } from '../types/BookProfile';

const getAdLink = (book: BookProfile) => {
  const ads = book.frontmatter.moshimo;
  if (ads.amazon) {
    return ads.amazon;
  }

  if (ads.rakuten) {
    return ads.rakuten;
  }

  if (ads.kindle) {
    return ads.kindle;
  }

  if (ads.kobo) {
    return ads.kobo;
  }

  return `/${book.frontmatter.slug}`;
};

export default getAdLink;
