import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { BookProfile } from '../types/BookProfile';
import config from '../website-config';
import * as _ from 'lodash';
import { colors } from '../styles/colors';

interface TagListProps {
  post: BookProfile;
}

export const TagList: React.FC<TagListProps> = ({ post }) => (
  <PostFullTags className="post-full-tags">
    {post.frontmatter.tags && post.frontmatter.tags.length > 0 && config.showAllTags && (
      post.frontmatter.tags.map((tag, idx) => (
        <React.Fragment key={tag}>
          {idx > 0 && (<>, &nbsp;</>)}
          <Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
        </React.Fragment>
      ))
    )}
    {post.frontmatter.tags && post.frontmatter.tags.length > 0 && !config.showAllTags && (
      <Link to={`/tags/${_.kebabCase(post.frontmatter.tags[0])}/`}>
        {post.frontmatter.tags[0]}
      </Link>
    )}
  </PostFullTags>
);

const PostFullTags = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.6rem;
  line-height: 2em;
  text-transform: uppercase;
  a {
    color: #303a3e;
  }
`;
