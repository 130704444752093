/* eslint-disable react/react-in-jsx-scope */
import { BookProfile } from '../types/BookProfile';
import { resolveBook } from '../lib/resolveBook';
import getAdLink from '../lib/getAdLink';

interface InlineBookLinkProps {
  book: string;
  books: BookProfile[];
}

export const InlineBookLink: React.FC<InlineBookLinkProps> = props => {
  const statement = `『${props.book}』`;

  const book: BookProfile | undefined = resolveBook(props.book, props.books);

  if (book === undefined) {
    return <span>{statement}</span>;
  }

  const link = getAdLink(book);

  return <a href={link}>{statement}</a>;
};
