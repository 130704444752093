/* eslint-disable react/react-in-jsx-scope */
import { resolvePost } from '../lib/resolvePost';
import { PageContext } from '../templates/post';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface BookCardProps {
  post: string;
  posts: PageContext[];
}

export const MDXPostCard: React.FC<BookCardProps> = props => {
  const post: PageContext | undefined = resolvePost(props.post, props.posts);

  if (post === undefined) {
    console.error(`MDXPostCard: Could not find post ${props.post}`);
    return <div />;
  }

  return (
    <div
      style={{
      }}
    >
      <Link className="post-card-image-link" to={`/${post.frontmatter.slug}`}>
        {post.frontmatter?.image.card && (
          <GatsbyImage
            image={getImage(post.frontmatter.image.card)!}
            alt={`${post.frontmatter.title} cover image`}
            imgStyle={{ width: '50%' }}
          />
        )}
      </Link>
    </div>
  );
};
