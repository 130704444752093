/* eslint-disable react/react-in-jsx-scope */
import { BookProfile } from '../types/BookProfile';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { resolveBook } from '../lib/resolveBook';
import { Link } from 'gatsby';
import { TagList } from './TagList';
import BookCardAffilication from './BookCardAffilication';
import { css } from '@emotion/react';
import getAdLink from '../lib/getAdLink';

export interface BookCardProps {
  book: string;
  books: BookProfile[];
}

const LinkToShohyo: React.FC<{ book: BookProfile }> = props => {
  if (props.book.frontmatter.draft) {
    return <div />;
  }

  return (
    <div className="link-to-shohyo">
      <Link to={`/${props.book.frontmatter.slug}`}>この本の要約・紹介を読む→</Link>
    </div>
  );
};

export const BookCard: React.FC<BookCardProps> = props => {
  const book: BookProfile | undefined = resolveBook(props.book, props.books);

  if (book === undefined) {
    console.error(`BookCard: Could not find book ${props.book}`);
    return <div />;
  }

  return (
    <div css={BookCardCss}>
      <div className="book-cover-wrapper">
        <a href={getAdLink(book)}>
          <GatsbyImage
            imgClassName="book-cover"
            image={getImage(book.frontmatter.image.book)!}
            style={{ height: '100%' }}
            alt={book.frontmatter.title}
          />
        </a>
      </div>
      <div className="book-content">
        <a href={getAdLink(book)}>{props.book}</a>
        <TagList post={book} />
        <LinkToShohyo book={book} />
        <BookCardAffilication moshimo={book.frontmatter.moshimo} />
      </div>
    </div>
  );
};

const BookCardCss = css`
  @media (max-width: 500px) {

  }
  @media (min-width: 500px) {
    display: flex;
    flexWrap: wrap;
    padding: 10px 10px 10px 10px;
    margin: 20px 20px 20px 20px;
  }
  
  .link-to-shohyo  {
    font-size: 80%;
  }

  .book-cover-wrapper {
    @media (max-width: 500px) {
      width: 60%;
      margin: 5% 20%;
    }

    @media (min-width: 500px) {
      height: 250px;
      width : 20vw;
    }

    .book-cover {
      height: 100%;
  
      @media (max-width: 500px) {
        width: 100%;
      }
  
      @media (min-width: 500px) {
        object-fit: contain !important;
        padding: 10px 10px 10px 10px;
        margin: 0;
      }
    }
  }

  .book-content {
    @media (max-width: 500px) {
      padding: 5% 10%;
    }
  }
`;
